// projects filters
.filter-modal {
    position: absolute;
    top: calc(100% + 7px);
    right: 0;
    background-color: #FBFBFC;
    border: 1px solid #C1C1C3;
    cursor: default;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);

    .custom-control-label {
        font-family: "Roboto";
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #656566;
        text-transform: initial;
    }

    .custom-control {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #E4E4E5;
        }

        &:first-of-type {
            &::after {
                background-color: #464647;
            }
        }
    }

    .body {
        .custom-control {
            &:last-child {
                &::after {
                   display: none;
                }
            }
        }

        .title {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: #000000;
            text-align: left;
            padding: 38px 14px 14px 14px;
        }
    }

    .custom-control-label:hover {
        cursor: pointer;
    }
}