.associate-gallery {
  .associate-item {
    filter: grayscale(1);
    transition: 0.3s ease;
    // padding-top: 1rem;
    padding-bottom: 2rem;

    .associate-img {
      width: 100%;
      height: 120px;
      object-fit: contain;
      object-position: center;
      // border: 1px solid rgb(101, 101, 102);
      @media (max-width: 768px) {
        height: 100px;
      }
    }
    .associate-title {
      font-size: small;
      letter-spacing: 0.028rem;
      line-height: 0.8rem;
      font-weight: 200;
      text-align: center;
      margin-top: 0.3rem;
      color: rgb(101, 101, 102);
    }

    &:hover {
      filter: grayscale(0);
      // background-color: rgb(101, 101, 102);
      .associate-title {
        color: darken($color: rgb(101, 101, 102), $amount: 50%);
      }
    }

    @media (max-width: 768px) {
      filter: grayscale(0);
    }
  }
}
.fix-modal {
  z-index: 99999;
}
