.toast {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 16px auto;
  max-width: 600px;
  border-radius: 4px;
  background-color: #252525;

  .toast-header {
    color: #c1c1c3;
    .close {
      color: #c1c1c3;
    }
  }
  transition: translateY 0.25s ease;
}
