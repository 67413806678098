$container-width: 1350px;

.asides.gdpr {
  font-family: 'Roboto', sans-serif;

  .gdpr-header {
    background-color: #0e2e47;
    padding: 52px 20px 80px 20px;

    h1 {
      font-weight: 300;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.87);
      margin: 0 auto;
    }
  }

  .search-section {
    transform: translateY(-50%);

    .input-group {
      max-width: 660px;

      input {
        padding: 13px 10px 13px 23px;
        background: #ffffff;
        border: 2px solid #0e2e47;
        border-radius: 4px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:focus {
          box-shadow: none;
        }
      }

      button {
        background: #ffffff;
        border: 2px solid #0e2e47;
        border-radius: 4px;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 15px 25px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .asides-wrapper {
    display: flex;
    max-width: $container-width;
    margin-top: 124px;

    .main-row {
      flex-direction: column;

      &.fix-navigation {
        position: fixed;
        width: 1350px;
      }

      @media (max-width: 768px) {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding: 0 15px;
      }
    }

    &.tabs-nav {
      h6 {
        cursor: pointer;
      }
      .aside-display {
        max-width: 660px;

        section {
          margin-bottom: 85px;

          &:first-child {
            h2 {
              padding-top: 0;
              border-top: none;
            }
          }

          ul {
            li {
              font-size: 15px;
              font-weight: 300;
              letter-spacing: 0.25px;
              list-style-type: circle;
            }
          }

          a {
            color: #000000;
            text-decoration: underline;
          }

          @media (max-width: 767px) {
            margin-bottom: 55px;
          }
        }

        @media (min-width: 767px) and (max-width: ($container-width - 1)) {
          max-width: calc(100% - 315px);
          margin-right: 0 !important;
        }
      }

      .close-headings-modal {
        display: none;
      }

      .tabs-headings-wrapper {
        .nav-label {
          display: none;
        }
      }

      @media (max-width: 767px) {
        .tabs-headings-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: #f9f9f9;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 4% 5%;
          opacity: 1;

          &.to-be-hidden {
            opacity: 0;
          }

          &.not-displayed {
            display: none;
          }

          .close-headings-modal {
            height: 56px;
            width: 56px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            display: flex;
            background-color: #ffffff;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
          }

          .nav-label {
            display: flex;
            margin-top: auto;
            margin-bottom: auto;
            padding: 20px;

            span {
              margin: 0 auto;
              font-size: 12px;
              line-height: 28px;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: rgba(14, 46, 71, 0.54);
            }
          }
        }
      }

      .tabs-headings {
        background-color: transparent;
        width: 315px;
        margin-bottom: 25px;
        position: relative;
        overflow: auto;

        &::before {
          content: '';
          width: 4px;
          height: 100%;
          background-color: #e1e1e1;
          display: block;
          position: absolute;
          left: 0;
          border-radius: 2px;
          top: 0;
        }

        @media (min-width: 768px) {
          &.aside-fixed {
            position: fixed;
            top: 38px;
          }
        }

        @media (max-width: 768px) {
          margin-bottom: 48px;
        }

        h6 {
          transition: 0.3s;
          margin: 14px 0;
          padding: 7px 0 7px 30px;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: rgba(0, 0, 0, 0.54);

          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }

          &.tab-nav-active {
            position: relative;
            color: #000000;

            &::before {
              content: '';
              width: 4px;
              height: 100%;
              background-color: #f2545b;
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 2px;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 0;
      margin-top: 21px;
    }
  }

  &.gdpr {
    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      letter-spacing: 0.15px;
      color: #000000;
      margin-bottom: 30px;
      padding-top: 80px;
      border-top: 2px solid rgba(0, 0, 0, 0.22);

      &::first-letter {
        color: #f2545b;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.76);
      margin-top: 40px;
    }
    h4 {
      font-size: 16px;
      margin-top: 18px;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.76);
    }

    @media (max-width: 767px) {
      h2 {
        font-size: 24px;
        line-height: 28px;
        padding-top: 50px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .open-headings-modal-wrapper {
    display: none;
  }

  @media (max-width: 767px) {
    .open-headings-modal-wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      display: flex;
      justify-content: center;
      padding: 4%;

      .open-headings-modal {
        height: 56px;
        width: 56px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #ffffff;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
