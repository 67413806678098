.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-wrapper {
    max-width: 380px;
    min-width: 100px;
  }

  .info {
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  label {
    margin-bottom: 0.5em;
    color: #444;
    font-weight: lighter;
    font-size: 12px;
    padding: 0 18px;
  }

  input,
  textarea {
    padding: 10px 10px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #a3a3a4;
  }

  input::placeholder,
  textarea::placeholder {
    font-size: 1em;
    color: rgba(0, 0, 0, 0.3);
  }

  input.error,
  textarea.error {
    border: 1px solid rgb(255, 0, 0);
  }

  .error-message {
    color: #db2269;
    font-size: 0.625em;
    padding: 0 14px;
  }

  .fullName,
  .email,
  .password,
  .phone,
  .message,
  .submit {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
  }

  .char-counter {
    text-align: right;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
    margin: 5px 0 20px 0;
  }

  .small {
    font-size: 10px;
    color: #000000;

    a {
      color: #1b75bc;
    }
  }
}
