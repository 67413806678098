@font-face {
  font-family: 'Corisande';
  src: url('../../assets/fonts/corisande-light-hr.ttf');
  font-weight: lighter;
  font-display: fallback;
}

@font-face {
  font-family: 'Corisande';
  src: url('../../assets/fonts/corisande-light-hr.ttf');
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: 'Corisande';
  src: url('../../assets/fonts/corisande-regular-hr.ttf');
  font-weight: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Corisande';
  src: url('../../assets/fonts/corisande-regular-hr.ttf');
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'Corisande';
  src: url('../../assets/fonts/corisande-bold-hr.ttf');
  font-weight: bold;
  font-display: fallback;
}
@font-face {
  font-family: 'Corisande';
  src: url('../../assets/fonts/corisande-bold-hr.ttf');
  font-weight: 500;
  font-display: fallback;
}
