// header logo
.logo-html {
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    background-image: none;
    background-color: #1b75bc;
    border-color: transparent !important;

    .txt {
      color: #ffffff;
    }

    * {
      border-color: #ffffff !important;
    }
  }

  .blok {
    font-size: 24.6px;
    line-height: 35.1px;
    font-weight: 300;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1.2px;
    color: #656566;
    padding-left: 12px;
  }

  .a3 {
    font-weight: bold;
    font-size: 30.5px;
    line-height: 40.9px;
    letter-spacing: -1.1px;
    color: #656566;
    padding-left: 13.6px;
  }
}

// navigation items list
.navigation-list {
  li {
    a {
      padding: 3px 19px;
      color: #7a7a7b;
      font-size: 14px;
      text-transform: uppercase;

      &:hover {
        color: #14588d;
        text-decoration: none;
      }
    }
    .active {
      color: #14588d;
      text-decoration: none;
      // font-weight: 100;
    }
  }
}

// navigation social list
.social-list {
  li {
    a {
      font-size: 16px;
      color: #656566;

      &:hover {
        color: #14588d;
        text-decoration: none;
      }
    }
  }
}

// navigation addons
.navigation-addon {
  button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
  }
  .btn-outline-dark {
    &:hover svg path,
    &:hover rect {
      fill: #fff;
    }
  }
}

.members {
  padding: 56px;
}

// team members
.team-member {
  text-align: center;

  & h3,
  & a {
    font-family: 'Roboto';
  }

  img {
    width: 121px;
    margin-bottom: 16px;
    filter: grayscale(100%);
  }

  h3 {
    color: $primary;
    font-size: 20px;
    line-height: 23px;
    font-weight: bold;
    letter-spacing: 0.15px;
  }

  .email,
  .phone {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 8px;
  }
}

// backgrounds
.bg-diagonale {
  position: relative;

  &:before {
    content: '';
    background-image: url('/images/bg-diagonale-light.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 0.3s;
  }
}

.bg-diagonale-light {
  background-image: url('/images/bg-diagonale-light.png');
}

// project details breadcrumbs
.breadcrumbs {
  font-family: 'Corisande';
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: $black;

  .bc-details {
    color: $gray-650;
  }

  svg {
    color: black;
    margin: 0 44px;
  }

  @media (max-width: 767px) {
    font-size: 18px;

    svg {
      margin: 0 18px;
    }
  }

  @media (max-width: 576px) {
    font-size: smaller;
  }
}

// section - project details
.project-bc-social {
  .social-btns {
    li {
      a {
        font-size: 24px;
      }
    }
  }
}

// close project modal
.btn-close-project-modal {
  background-color: transparent;
  color: rgba(29, 29, 29, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 17px;
  font-size: 23px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
}

// language picker
.language-picker {
  .current {
    cursor: pointer;

    &,
    .item {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.25px;
      color: #656566;
      text-align: center;

      &:hover {
        text-decoration: none;
      }
    }

    .options {
      bottom: 100%;
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;
      left: -50%;
      transform: translateX(50%);

      .item {
        display: block;
      }
    }

    &:hover {
      & > * {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

// projects tabs
.tabs-navigation {
  a {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.5px;
    color: #a3a3a4;
    padding: 13px 37px;
    position: relative;
    transition: 0.3s;

    &::after {
      content: '';
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #a3a3a4;
      transition: 0.3s;
    }

    &:hover,
    &.active-tab {
      color: #464647;
      text-decoration: none;

      &:after {
        background-color: #1b75bc;
      }
    }
  }
}

// projects sectionb
.projects-section {
  .projects-map {
    height: 400px;
  }
}

// editor hidden
.editor-hidden {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  padding: 30px;
  text-align: center;
}
