@media (max-width: 767px) {
  .members {
    padding: 33px;
  }
}

@media (min-width: 768px) {
  .projects-section {
    .projects-map {
      height: 100%;
    }
  }

  // border bottom
  .border-bottom-md {
    border-bottom: 1px solid #656566 !important;
  }
  .border-bottom-md-0 {
    border-bottom: none !important;
  }
  // border left
  .border-left-md {
    border-left: 1px solid #656566 !important;
  }
  .border-left-md-0 {
    border-left: none !important;
  }
  // border top
  .border-top-md {
    border-top: 1px solid #656566 !important;
  }
  .border-top-md-0 {
    border-top: none !important;
  }
  // border right
  .border-right-md {
    border-right: 1px solid #656566 !important;
  }
  .border-right-md-0 {
    border-right: none !important;
  }
}

@media (min-width: 992px) {
  .addon-sm {
    display: none;
  }
  .border-bottom-lg-0 {
    border-bottom: none !important;
  }
  .border-top-lg-0 {
    border-top: none !important;
  }
  .border-left-lg-0 {
    border-left: none !important;
  }
  .border-right-lg-0 {
    border-right: none !important;
  }
  .border-bottom-lg {
    border-bottom: 1px solid #656566 !important;
  }
  .border-top-lg {
    border-top: 1px solid #656566 !important;
  }
  .border-left-lg {
    border-left: 1px solid #656566 !important;
  }
  .border-right-lg {
    border-right: 1px solid #656566 !important;
  }
}

@media (min-width: 992px) and (max-width: 1330px) {
  .border-right-nav-bp-0 {
    border-right: 0 !important;
  }

  $nav-height: 120px !important;

  .central-navigation {
    height: $nav-height;
  }

  .body-content {
    margin-top: $nav-height;
  }
}

@media (max-width: 991px) {
  .addon-sm {
    .navigation-addon {
      padding: 10px 10px 10px 24px;
      .tabs-navigation {
        a {
          display: inline-block;
          padding: 0;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.6);

          svg path {
            fill: rgba(0, 0, 0, 0.6);
          }

          &.active-tab {
            color: #1b75bc;

            svg path {
              fill: #1b75bc;
            }
          }

          &::after {
            display: none;
          }
        }
      }
      .btn-outline-dark {
        &:hover svg path,
        &:hover rect {
          fill: #fff;
        }
      }
      .filter-modal {
        z-index: 1;
      }
    }
  }
}

@media (max-width: $nav-bp) {
  .indented-up {
    // margin-top: 0 !important;
    z-index: 0 !important;
  }
}

@media (max-width: 1140px) {
  .only-desktop {
    display: none;
  }
  .tui-image-editor-container {
    display: none;
  }
}
// @media (min-width: 1440px) {
//   .only-not-desktop {
//     display: none;
//   }
// }
