.hover-select {
  cursor: pointer;

  // &,
  .item {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.25px;
    color: #656566;
    text-align: center;
    text-transform: uppercase;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    &.current {
      opacity: 1;
      visibility: visible;
    }
    &:hover {
      text-decoration: none;
      color: #1b75bc;
    }
  }

  .option {
    transition: 0.3s;
  }

  &:hover {
    & > * {
      opacity: 1;
      visibility: visible;
    }
  }
}
