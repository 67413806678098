.slider {
  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
        display: flex;
        // align-items: stretch;

        .slick-slide {
          display: flex;
          height: auto;
          & > div {
            flex: 1;
            img {
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.home-footer {
  align-self: center;
  width: 100%;
  height: 55px;
}

.visibility-signs {
  position: fixed !important;
  width: (5/12) * 100vw+1.5vw;
  bottom: 0;
  left: 0;
  height: 77px;
  transition: 0.3s background-color;

  /* &:hover {
    background-color: #fff;
  } */
}
