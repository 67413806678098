// .project-gallery {

.gallery-item {
  // box-sizing: border-box;
  // padding-top: 16/9 * 100%;
  // height: 0;

  &:hover {
    .gallery-img {
      transform: scale(1.08);
    }
  }
}
.gallery-img {
  // box-sizing: content-box;
  // margin-top: -100%;
  transform: scale(1.05);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.3s linear;
}
.item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  padding-right: 4%;
  padding-bottom: 1%;
  color: white;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6726891440169818) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(81, 0, 255, 0) 100%
  );

  transition: 0.3s ease;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  // @media (max-width: 768px) {
  //   opacity: 1;
  // font-size: medium;

  // background: linear-gradient(
  //   0deg,
  //   rgba(0, 0, 0, 1) 0%,
  //   rgba(0, 0, 0, 0.6726891440169818) 20%,
  //   rgba(0, 0, 0, 0) 30%,
  //   rgba(0, 0, 0, 0) 100%
  // );
  // }
}
// }
