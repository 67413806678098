.about-section {
  h2 {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #656566;
    margin-bottom: 48px;

    span {
      padding-top: 25px;
      display: inline-block;
      position: relative;
      font-family: 'Roboto';

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background-color: $gray-650;
      }
    }
  }
  .about-text-body {
    text-align: justify;
    hyphens: auto;
    font-weight: 300;
    letter-spacing: 0.25px;
    span {
      color: rgba(0, 0, 0, 0.6);
    }
    h7 {
      font-weight: normal;
      color: #212529;
    }
  }

  .bg-diagonale {
    transition: 0.3s;

    &:hover {
      background-color: rgb(255, 255, 255);

      &::before {
        opacity: 0;
      }
    }

    &:before {
      content: '';
      background-image: url('/images/bg-diagonale-light.png');
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: 0.3s;
    }
  }

  .partner-img {
    display: block;
    max-width: 100%;
    height: 100px;
    object-fit: contain;
    object-position: center;
    transition: filter 0.5s ease;
    filter: grayscale(1);
    &:hover {
      filter: grayscale(0);
    }
    @media (max-width: 768px) {
      filter: grayscale(0);
    }
  }
}
