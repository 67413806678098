@import '../../assets/constants/styles.scss';

.App {
  font-family: $general-font-family;
  height: 100%;

  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote {
    font-family: $general-font-family;
  }
}
