// // navigation fixation
.aside-left {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 113px;

  .logo-html {
    height: 155px;
  }
}
.aside-right {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  width: 113px;
}

.central-content {
  // width: calc(100% - 226px);
  display: flex;
  flex-direction: column;
  // margin: 0 auto;
  min-height: 100vh;
  width: 100%;

  .central-navigation {
    height: $nav-height;
    position: fixed;
    top: 0;
    left: 128px;
    width: calc(100% - 226px);
    z-index: 2;
  }

  @media (min-width: 992px) {
    padding: 0 128px;
  }
}

.body-content {
  margin-top: $nav-height;
}

@media (max-width: 991px) {
  $nav-height: 55px;

  .aside-left {
    height: $nav-height;
    width: 100%;
    background-image: url('/images/bg-diagonale-light.png');
    z-index: 4;
    justify-content: center;
    padding: 0 16px 0 24px;

    .navigation-top-sm {
      .logo-sm-text {
        font-family: 'Corisande';
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
        color: #464647;
        margin-left: 10px;

        b {
          font-weight: bold;
        }
      }

      .menu-sm-triger {
        font-family: 'Corisande';
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 5px;
        color: #000000;
        text-transform: uppercase;
        position: relative;

        svg {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(-50%, -50%) scale(0);
          font-size: 27px;
          transition: 0.3s;
        }

        span {
          transition: 0.3s;
          display: inline-block;
        }
      }
    }
  }

  .aside-right {
    height: 55px;
    top: auto;
    bottom: 0;
    width: 100%;
    flex-direction: row !important;
    z-index: 2;
    right: -100%;
    transition: 0.3s;

    .social-list {
      flex-direction: row !important;
      // margin: 0 !important;
    }

    &.bg-diagonale {
      &::before {
        background-image: none;
      }
    }
  }

  .central-content {
    // width: 100%;

    .central-navigation {
      width: 100%;
      min-height: 100vh;
      left: calc(100% + 15px);
      transition: 0.3s;

      & > div {
        height: 100%;

        &::before {
          background-image: url('/images/bg-diagonale-light.png');
        }
      }

      .navigation-list {
        padding-top: 70px;
        padding-bottom: 70px;
        max-height: 100vh;
        overflow: auto;
        flex-wrap: nowrap !important;
        align-items: flex-start !important;

        li {
          a {
            display: block;
            padding: 18px 19px;
            font-family: 'Roboto';
            font-weight: bold;
            font-size: 25px;
            line-height: 38px;
            letter-spacing: 2px;
            color: #000000;

            // &::first-letter {
            //   font-size: 40px;
            // }
          }
        }
      }
    }

    .body-content {
      margin-top: $nav-height;
    }
  }

  .nav-sm-detector {
    &.nav-shown {
      .central-content {
        .central-navigation {
          left: 15px;
          z-index: 3;
        }
      }

      .aside-right {
        right: 0;
        z-index: 4;
      }

      .aside-left {
        z-index: 4;
      }

      .menu-sm-triger {
        span {
          transform: scale(0);
        }
        svg {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .visibility-signs {
    &.landing {
      left: 113px;
      padding-left: 15px;
      padding-right: 14px;
      padding-bottom: 8px;
    }
  }
}