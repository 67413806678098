.list {
  position: relative;

  .aside-text {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-75%, 400%) rotate(-90deg);
    font-size: 1rem;
    font-weight: bold;
    color: #000000;

    @media (max-width: 991px) {
      z-index: 1;
      transform: none;
      // transform: translateY(-100%);
      top: -1.7rem;
      margin-left: 1rem;
      // position: relative;
      // margin: 42px 16px 8px 16px;
    }
  }
  @media (max-width: 991px) {
    margin-top: 2rem;
  }
  &:last-of-type {
    margin-bottom: 0 !important;
  }
}
