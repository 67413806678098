.project-details {
  .slick-dots {
    bottom: 0;
    text-align: left;
    padding-left: 30px;
    padding-bottom: 10px;

    li {
      button {
        &::before {
          font-size: 12px;
          line-height: 20px;
          position: absolute;
          width: 12px;
          height: 12px;
          content: '';
          border: 2px solid #464647;
          border-radius: 50%;
          opacity: 1;
        }
      }

      &.slick-active {
        button {
          &::before {
            background-color: #464647;
          }
        }
      }
    }
  }

  .project-description {
    padding: 47px 8%;

    h3 {
      font-family: 'Corisande';
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 0;
    }

    h6 {
      font-family: 'Roboto';
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 30px;
    }

    .desc-item {
      font-family: 'Roboto';
      margin-bottom: 25px;

      .label {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: hsla(0, 0%, 100%, 0.7);
        text-transform: uppercase;
      }

      .content {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #ffffff;
      }
    }
  }
}

.related-projects {
  h6 {
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    padding: 66px 0 33px 0;
    margin-bottom: 0;
  }

  .item {
    img {
      max-width: 100%;
    }
  }
}

.details-footer-section {
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;

  p {
    font-family: 'Corisande';
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
  }
}
.project-modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  border: none;
  overflow: auto;
  // max-width: 100%;
  border-radius: 0;
  z-index: 100;
  background-color: white;
}
