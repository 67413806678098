.reference-gallery {
  .reference-item {
    filter: grayscale(1);
    transition: 0.3s ease;
    // padding-top: 1rem;
    padding-bottom: 1rem;

    .reference-img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      border: 1px solid #969696;
      transition: 0.3s;
    }
    .reference-title {
      font-size: small;
      letter-spacing: 0.028rem;
      line-height: 1rem;
      font-weight: 200;
      text-align: center;
      margin-top: 0.3rem;
      color: rgb(101, 101, 102);
    }

    &:hover {
      filter: grayscale(0);
      // background-color: rgb(101, 101, 102);
      .reference-title {
        // font-weight: 600;
        color: darken($color: rgb(101, 101, 102), $amount: 50%);
      }
      .reference-img {
        box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.78);
        cursor: pointer;
      }
    }
  }
}
.fix-modal {
  z-index: 99999;
}
