.tui-colorpicker-palette-container li {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;

    .tui-colorpicker-palette-button {
        display: block;
        overflow: hidden;
        outline: none;
        margin: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        border: 1px solid #ccc;
        cursor: pointer;
    }
}

.tui-colorpicker-palette-preview {
    width: 12px;
    height: 12px;
    border: 1px solid #ccc;
    overflow: hidden;
}

ul.tui-colorpicker-clearfix {
    // zoom: 1;
    display: flex;
    flex-wrap: wrap;
}