.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 9/16 * 100%;
  position: relative;

  .aspect-ratio-box-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
