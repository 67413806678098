$bar-width: 113px;

.architect {
  &.imgs-wrapper {
    &.quartered {
      height: 100vh;
    }

    &.halved {
      height: 100%;
      position: relative;

      .fixed-bg-wrappers {
        .resizeable {
          .img-item {
            height: 100%;
          }

          &.item-1 {
            &::before {
              display: none;
            }
          }
        }
      }

      &::before {
        content: '';
        height: 50px;
        width: 100%;
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .item-3 {
        border-left: 1px solid #ffffff;

        &.resizeable {
          width: 65%;
        }
      }

      &:hover {
        cursor: none;
      }
    }

    .fixed-bg-wrappers {
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;

      .resizeable {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;

        .img-item {
          height: 100vh;
          width: 100vw;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;

          &.up-left {
            // background-image: url('../../assets/images/img-1.jpg');
            top: 0;
            left: 0;
          }
          &.down-left {
            // background-image: url('../../assets/images/img-2.jpg');
            bottom: 0;
            left: 0;
          }
          &.up-right {
            // background-image: url('../../assets/images/img-12.jpg');
            top: 0;
            right: 0;
          }
          &.down-right {
            // background-image: url('../../assets/images/img-4.jpg');
            bottom: 0;
            right: 0;
          }
        }

        &.item-1 {
          top: 0;
          left: 0;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 555px;
            height: 447px;
            display: block;
            z-index: 1;
            background: linear-gradient(
              317.63deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0) 47.67%
            );
          }
        }
        &.item-2 {
          bottom: 0;
          left: 0;
        }
        &.item-3 {
          top: 0;
          right: 0;
        }
        &.item-4 {
          bottom: 0;
          right: 0;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 555px;
            height: 447px;
            display: block;
            z-index: 1;
            background: linear-gradient(
              317.63deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0) 47.67%
            );
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.quartered {
      display: none;
    }
  }
  margin-right: 0;
  @media (min-width: 996px) {
    &.quartered {
      margin-right: $bar-width;
    }
  }
}

// halved images detailed
.orientation-circle {
  height: 48px;
  width: 48px;
  background-color: white;
  position: absolute;
  bottom: 11px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  right: 0;

  span {
    position: absolute;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 0 16px;

    &.before {
      left: 0;
      transform: translateX(-100%);
    }

    &.after {
      right: 0;
      transform: translateX(100%);
    }
  }

  .arrows {
    color: rgba(0, 0, 0, 0.6);
    font-size: 28px;
    display: flex;
  }
}
