.road-info-table {
  border-collapse: separate;
  border-spacing: 0 6px;
  tr {
    th {
      color: rgba(0, 0, 0, 0.329);
      width: 5rem;
    }
    td {
      color: black;
    }
  }
}
