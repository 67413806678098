.landing-mobile {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;

  .cards {
    width: 100vw;
    align-self: center;
    .item {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      svg {
        margin-bottom: 8px;
        font-size: 24px;
        color: #000000;
      }

      span {
        font-family: 'Corisande';
        font-weight: bold;
        font-size: 16px;
        color: #464647;
      }
    }
  }
}
