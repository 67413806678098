.project-modal .cropper-content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    & > div {
        height: 100%;
    }
}