.slider-expand-btn {
  background: rgba(70, 70, 71, 0.5);
  border: 0px none;
  margin: 10px;
  padding: 0px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 30px;
  width: 30px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-slider {
  line-height: 0 !important;
}
