// site footer
footer {
    .copy-right {
        font-family: "Roboto";
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;        
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.6);
        padding: 27px;
        display: flex;
        align-items: center;

        .bullet {
            font-size: 6px;
            padding: 0 12px;
        }

        .privacy-policy {
            a {
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }
}